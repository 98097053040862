const ITEM = {
  STATUS: {
    NEW: "NEW",
    WIP: "WIP",
    SUBMITTED: "SUBMITTED",
    CANCELLED: "CANCELLED",
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    SCANNED: "SCANNED",
    RELEASED: "RELEASED",
    MATCHED: "MATCHED",
    NOT_MATCHED: "NOT_MATCHED",
    EXCEPTIONS: "EXCEPTIONS",
  },
};
ITEM.ORDER_STATUS = [
  { label: "New", value: ITEM.STATUS.NEW },
  { label: "In Progress", value: ITEM.STATUS.WIP },
  { label: "Pending", value: ITEM.STATUS.PENDING },
  { label: "Released", value: ITEM.STATUS.RELEASED },
  { label: "Cancelled", value: ITEM.STATUS.CANCELLED },
  { label: "Completed", value: ITEM.STATUS.COMPLETED },
  { label: "Exceptions", value: ITEM.STATUS.EXCEPTIONS },
];
ITEM.MATCH_STATUS = [
  { label: "Exceptions", value: ITEM.STATUS.EXCEPTIONS },
  { label: "Matched", value: ITEM.STATUS.MATCHED },
  { label: "Not Matched", value: ITEM.STATUS.NOT_MATCHED },
];

export { ITEM };

// prettier-ignore
export const BORDER_COLORS = {
  "NEW": "border-success",
  "WIP": "border-lblue3",
  "CANCELLED": "border-red3",
  "PENDING": "border-yellow3",
  "COMPLETED": "",
  "SCANNED": "border-primary",
  "RELEASED": "border-success",
  "MATCHED": "border-primary",
  "NOT_MATCHED": "border-red1",
  "EXCEPTIONS": "border-yellow1",
};

export const NOTES_BY = [
  {
    label: "Requestor",
    value: "R",
  },
  {
    label: "Order Operator",
    value: "O",
  },
  {
    label: "All",
    value: "B",
  },
];

export const SCAN_NOTES_BY = [
  {
    label: "Pathologist",
    value: "R",
  },
  {
    label: "Scan Operator",
    value: "O",
  },
  {
    label: "All",
    value: "B",
  },
];

export const PAGINATION = {
  pageNum: 1,
  pageSize: 10,
};

export const ENV_CONFIG = "envConfig";

export const MAX_CHARS_STRING = 1200;
export const MAX_CHARS_NOTES = 100;
export const MAX_CHARS_INPUT = 15;
export const MAX_CHARS_INPUT30 = 30;
export const MAX_CHARS_EMAIL_USERID = 74;

export const MAX_INTEGER = 999999999;
export const MAX_PHONE_NUMER = 999999999999999;
export const MAX_IAP_ID_LENGTH = 999999999999999999999999999999;
export const IDLE_TIMER = 600000;

export const MIN_TIMESTAMP = "00:00:00";
export const MAX_TIMESTAMP = "23:59:59";

export const SUCCESS_ALERT_DURATION = 3000;

export const ROLES_CD = [
  { label: "RC User", value: "RCUSER" },
  { label: "User Admin", value: "USER_ADMIN" },
  { label: "Scan Operator", value: "SCAN_OPERATOR" },
  { label: "Order Operator", value: "ORDER_OPERATOR" },
];

export const CONDITIONS = [
  { label: "Out", value: "OUT" },
  { label: "Perm Out", value: "PERM_OUT" },
  { label: "Destroyed", value: "DESTROYED" },
];

export const ERROR_CODES = {
  USER_DELETED: "ODS0230",
};

export const API_PATHS = {
  /* Common */
  CUSTOMERS: "customers",
  LOCATIONS: "locations",
  USERS_PROFILE: "users/profile",
  DEFAULT_LOCATION: "defaultLocation",
  /* Orders */
  ORDERS: "orders",
  ITEMS: "items",
  ORDERS_ITEM: "orders/item",
  ORDERS_ITEMS: "orders/items",
  ORDERS_SEARCH: "orders/search",
  ORDERS_ITEMS_SEARCH: "orders/items/search",
  ORDERS_ITEMS_ACCESSION: "orders/item/accession",
  RELEASE: "release",
  /* Scans */
  ACCESSION_SCAN: "accession/scan",
  ACCESSION: "accession",
  ACCESSION_OVERRIDE: "accession/scan/override",
  ACCESSION_PATIENT: "accession/patient",
  ACCESSION_SCAN_SLIDE: "accession/scan/slide",
  SCAN_SLIDE: "scan/slide",
  SCAN_OVERRIDE: "scan/override",
  /* Users */
  USERS: "users",
  ROLES: "roles",
  OKTA_RESET_PASSWORD: "okta-reset-password",
};
